import React, { type MouseEvent, useContext, type FC } from 'react'
import styles from './menu.module.css'

import { NavLink } from 'react-router-dom'
import { combineClassName } from '../../util/utilMethods'
import {
  MobileMenuContext,
  MobileMenuDispatch,
} from '../../context/mobileMenuContext'
import { Button } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'

const Menu: FC = () => {
  const { show } = useContext(MobileMenuContext)
  const { toggleMobileMenu } = useContext(MobileMenuDispatch)

  const navClass = ({ isActive }: { isActive: any }): string =>
    combineClassName(styles.nav, isActive ? styles.active : '')

  const openMemberShip = (e: MouseEvent): void => {
    e.preventDefault()
    toggleMobileMenu()
    window.open(
      process.env.REACT_APP_MEMBERSHIP_URL ?? 'https://hclub.herelplay.com',
      '_blank',
      'noopener noreferrer'
    )
  }
  return (
    <main
      className={combineClassName(
        styles.menu,
        !show ? styles.hide_mobile_menu : ''
      )}
    >
      <section className={styles.pages}>
        <NavLink to="/" className={navClass} onClick={toggleMobileMenu}>
          Home
        </NavLink>
        <NavLink to="/bookings" className={navClass} onClick={toggleMobileMenu}>
          Bookings
        </NavLink>
        <NavLink to="/dine" className={navClass} onClick={toggleMobileMenu}>
          Dine
        </NavLink>
        <NavLink
          to="/membership"
          className={combineClassName(styles.nav, styles.membership)}
          onClick={openMemberShip}
        >
          Membership
        </NavLink>
      </section>
      <HashLink
        to="/#contact"
        className="text-decoration-none text-reset"
        onClick={toggleMobileMenu}
      >
        <Button
          size="lg"
          className={combineClassName('w-100', styles.contact_us_btn)}
        >
          Contact us
        </Button>
      </HashLink>
    </main>
  )
}

export default Menu
